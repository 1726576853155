import getItem from '@haaretz/s-browser-storage-utils/getItem';
import setItem from '@haaretz/s-browser-storage-utils/setItem';

export type ConsumptionEventType = 'Start' | 'Stop';

export type TrackConsumptionEvent = {
  OrganizationId: string;
  ClipId: string;
  SessionId: string;
  Type: ConsumptionEventType;
  Position: number;
  SeqNumber: number;
  Timestamp: number;
};

type ConsumptionPayloadSource = 'MobileApp' | 'SmartSpeaker' | 'CustomWeb' | 'Custom';
type ConsumptionPayloadSourceDisabled = 'Disabled';

export type ConsumptionPayload = {
  Source: ConsumptionPayloadSource | ConsumptionPayloadSourceDisabled;
  Events: TrackConsumptionEvent[];
  Completed: true;
};

export interface ConsumptionApiResponse {
  Enabled: boolean;
}

export const omnyEventTypes = {
  stop: 'Stop',
  start: 'Start',
} as const;

const OMNY_EVENT_KEY = '__mnyCnsmptn';

export const getConsumptionEvents = (): Record<string, TrackConsumptionEvent[]> | null => {
  const raw = getItem({ key: OMNY_EVENT_KEY, storage: 'local' });

  try {
    return raw ? JSON.parse(raw) : null;
  } catch (err) {
    console.error('Failed to parse Omny session events from localStorage', err);
    return null;
  }
};

export const writeOmnyEventsToStorage = (events: Record<string, TrackConsumptionEvent[]>) => {
  setItem({
    key: OMNY_EVENT_KEY,
    value: JSON.stringify(events),
    storage: 'local',
  });
};

export const createSession = ({
  organizationId,
  clipId,
  source = 'CustomWeb',
}: {
  organizationId: string;
  clipId: string;
  source?: ConsumptionPayloadSource | ConsumptionPayloadSourceDisabled;
}) => {
  let events: TrackConsumptionEvent[] = [];
  let seqNumber = 1;
  let sessionId = crypto.randomUUID();

  const getTimestamp = () => Math.floor(Date.now() / 1000);

  const addEvent = (type: ConsumptionEventType, position: number) => {
    const event: TrackConsumptionEvent = {
      OrganizationId: organizationId,
      ClipId: clipId,
      SessionId: sessionId,
      Type: type,
      Position: position,
      SeqNumber: seqNumber,
      Timestamp: getTimestamp(),
    };

    events.push(event);
    seqNumber += 1;
  };

  const trackStart = (position: number) => addEvent('Start', position);
  const trackStop = (position: number) => addEvent('Stop', position);

  const flush = (): ConsumptionPayload | null => {
    if (events.length === 0) return null;

    const payload: ConsumptionPayload = {
      Source: source,
      Events: events,
      Completed: true,
    };

    events = [];
    seqNumber = 1;
    sessionId = crypto.randomUUID();

    return payload;
  };
  // Cleanup
  // delete stored[sessionId];
  // writeOmnyEventsToStorage(stored);

  return {
    trackStart,
    trackStop,
    flush,
  };
};
