'use client';

import React from 'react';

import throttleFn from '../utils/throttleFn';

interface Props {
  audio: HTMLAudioElement | null;
  children: React.ReactNode;
}

export interface AudioPlayerTimeContextValue {
  currentTime: number;
  durationTime: number;
}

export const AudioPlayerTimeContext = React.createContext<AudioPlayerTimeContextValue>({
  currentTime: 0,
  durationTime: -1,
});

export const useAudioPlayerTime = () => React.use(AudioPlayerTimeContext);

export default function AudioPlayerTimeProvider({ audio, children }: Props) {
  const [currentTime, setCurrentTime] = React.useState(0);
  const [durationTime, setDurationTime] = React.useState(-1);

  React.useEffect(() => {
    if (!audio) return undefined;

    const updateCurrentTime = throttleFn(() => {
      setCurrentTime(audio.currentTime);
    }, 100);

    const updateDuration = () => {
      setDurationTime(audio.duration || -1);
    };

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadeddata', updateDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadeddata', updateDuration);
    };
  }, [audio]);
  return (
    <AudioPlayerTimeContext.Provider value={{ currentTime, durationTime }}>
      {children}
    </AudioPlayerTimeContext.Provider>
  );
}
