import config from '@haaretz/l-config';
import { widthBpsInEm } from '@haaretz/l-theme-shared/lib/consts';
import buildBaseImgSrc from '@haaretz/s-image-utils/buildBaseImgSrc';
import buildBaseImgSrcset from '@haaretz/s-image-utils/buildBaseImgSrcset';
import buildSizesString from '@haaretz/s-image-utils/buildSizesString';
import getAspectData from '@haaretz/s-image-utils/getAspectData';

import type { FallbackSource, Source } from './HtzPicture';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';

export function generateMediaQueryForPreload(index: number, sources: Source[]) {
  const minWidth = widthBpsInEm[sources[index].from];
  const maxWidth =
    index < sources.length - 1 ? widthBpsInEm[sources[index + 1].from] - 0.01 : undefined;
  return maxWidth
    ? `(min-width:${minWidth}em) and (max-width:${maxWidth}em)`
    : `(min-width:${minWidth}em)`;
}

export function buildPriorityLoading({
  source,
  imgData,
  envOverride,
  contentId,
}: {
  source: Source | FallbackSource;
  imgData: ImageFragment['files'][number];
  envOverride: TEnv | undefined;
  contentId: string;
}) {
  const sizesString = source.sizes && buildSizesString(source.sizes);
  const env = envOverride || config.get('env');
  const minWidth = Math.min(...source.widths);
  const { isCropped, aspectRatio, aspectData } = getAspectData(source.aspect, imgData);

  const srcUrl = buildBaseImgSrc({
    aspect: source.aspect,
    aspectData,
    aspectRatio,
    contentId,
    env,
    height: undefined,
    imgData,
    isCropped,
    width: minWidth,
  });

  const srcSetUrl = buildBaseImgSrcset({
    src: srcUrl,
    widths: source.widths,
    aspectRatio,
    env,
  });

  return { sizesString, srcUrl, srcSetUrl };
}
