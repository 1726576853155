'use client';

import React from 'react';

const ToggleContext = React.createContext<boolean>(false);

export default function useToggleContext() {
  const shoudPrevent = React.use(ToggleContext);

  return shoudPrevent;
}
export function ToggleContextProvider({ children }: React.PropsWithChildren) {
  return <ToggleContext value={true}>{children}</ToggleContext>;
}
