'use client';

import config from '@haaretz/l-config';
import * as React from 'react';
import { useState, useCallback } from 'react';

import AudioPlayerContext from '../hooks/useAudioPlayer';
import {
  playbackRate,
  skipTime as skipTimeDefault,
  getAudioLinkWebView,
} from '../utils/audioPlayerUtils';
import { createSession } from '../utils/omnyConsumptionBiCollector';
import useOmnyConsumptionMutation from '../utils/useOmnyConsumptionMutation';

import AudioPlayerTimeProvider from './AudioPlayerTimeProvider';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { PodcastEpisodeFragment } from '@haaretz/s-fragments/PodcastEpisode';

export interface AudioPlayerProps {
  fileUrl: string;
  title: string;
  channel?: PodcastEpisodeFragment['channel'];
  image?: ImageFragment;
  children: React.ReactNode;
}

/**
 * clipIdRegex is a regex that matches the clipId from the fileUrl.
 */
const clipIdRegex = /\/([\w-]{36})\/audio\.mp3$/;
const organizationId = config.get('omnyOrganizationId');

export default function AudioPlayerClient({
  fileUrl,
  title,
  channel,
  children,
  image,
}: AudioPlayerProps) {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [wasNotPlayed, setWasNotPlayed] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playbackIndex, setPlaybackIndex] = useState<number>(0);
  const sendOmnyConsumption = useOmnyConsumptionMutation();
  const clipId = fileUrl.match(clipIdRegex)?.[1];
  const session: ReturnType<typeof createSession> | null = React.useMemo(() => {
    if (!clipId) return null;
    if (!clipId) {
      console.warn('Invalid clip ID — consumption analytics disabled.');
    }
    return createSession({ organizationId, clipId });
  }, [clipId]);

  const refCallback = useCallback((audioEl: HTMLAudioElement) => {
    setAudio(audioEl);
  }, []);

  const onPlayButtonClick = () => {
    if (!audio) {
      return;
    }
    if (wasNotPlayed) {
      setWasNotPlayed(false);
      audio.src = fileUrl;
      audio.load();
    } else {
      audio.play();
    }
  };

  const onPauseButtonClick = React.useCallback(() => {
    audio?.pause();
    if (session && audio) {
      session.trackStop(audio.currentTime);
    }
  }, [audio, session]);

  const onFlush = useCallback(() => {
    if (!session) return;

    const payload = session.flush();
    if (payload) {
      sendOmnyConsumption.mutate(payload);
    }
  }, [session, sendOmnyConsumption]);

  const onPlaying = React.useCallback(() => {
    setIsPlaying(true);
    if (session && audio) {
      session.trackStart(audio.currentTime);
    }
  }, [session, audio]);

  const onPause = () => {
    setIsPlaying(false);
  };

  const onLoadedData = React.useCallback(() => {
    audio?.play();
  }, [audio]);

  const onSkip = React.useCallback(
    (skipTime = skipTimeDefault) => {
      if (audio && audio.currentTime) {
        audio.currentTime = audio.currentTime + skipTime;
      }
    },
    [audio]
  );

  const onNextPlayBackRate = React.useCallback(() => {
    if (!audio) return;
    const getNextRateIndex = (currentIndex: number): number => {
      if (currentIndex + 1 < playbackRate.length) {
        return currentIndex + 1;
      }
      return 0;
    };
    audio.playbackRate = playbackRate[getNextRateIndex(playbackIndex)];
    setPlaybackIndex(getNextRateIndex);
  }, [audio, playbackIndex]);

  const onPlayingCallback = React.useCallback(
    (callback: () => void) => {
      if (!audio) return;
      audio.onplaying = () => {
        if (typeof callback === 'function') {
          callback();
        }
      };
    },
    [audio]
  );

  React.useEffect(() => {
    if (!session) return () => {};

    window.addEventListener('beforeunload', onFlush);

    return () => {
      window.removeEventListener('beforeunload', onFlush);
    };
  }, [onFlush, session]);

  const webviewLink = getAudioLinkWebView({
    image,
    mobileTitle: title,
    channelLabel: channel?.channelName,
    channelLinks: channel?.links,
    fileUrl,
  });

  const onPauseCallback = React.useCallback(
    (callback: () => void) => {
      if (!audio) return;
      audio.onpause = () => {
        if (typeof callback === 'function') {
          callback();
        }
      };
    },
    [audio]
  );

  const onEnded = React.useCallback(() => {
    setIsPlaying(false);
    if (session && audio) {
      session.trackStop(audio.currentTime);
    }
  }, [audio, session]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={refCallback}
        onLoadedData={onLoadedData}
        onPlaying={onPlaying}
        onPause={onPause}
        onEnded={onEnded}
      />
      <AudioPlayerTimeProvider audio={audio}>
        <AudioPlayerContext
          value={{
            audio,
            title,
            channelName: channel?.channelName,
            isPlaying,
            wasNotPlayed,
            playbackIndex,
            webviewLink,
            onSkip,
            onPlayingCallback,
            onPauseCallback,
            onPlayButtonClick,
            onPauseButtonClick,
            onNextPlayBackRate,
          }}
        >
          {children}
        </AudioPlayerContext>
      </AudioPlayerTimeProvider>
    </>
  );
}
