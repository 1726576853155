'use client';

import config from '@haaretz/l-config';
import { useMutation } from '@tanstack/react-query';

import type {
  ConsumptionApiResponse,
  ConsumptionPayload,
} from '../utils/omnyConsumptionBiCollector';

const organizationId = config.get('omnyOrganizationId');
const url = `https://traffic.omny.fm/api/consumption/events?organizationId=${organizationId}`;

export default function useOmnyConsumptionMutation() {
  return useMutation<ConsumptionApiResponse, Error, ConsumptionPayload>({
    mutationKey: ['omny-consumption-events'],
    mutationFn: async (payload: ConsumptionPayload) => {
      const res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
        keepalive: true,
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(`Omny API Error (${res.status}): ${errorText}`);
      }

      const data: ConsumptionApiResponse = await res.json();
      return data;
    },
    useErrorBoundary: false,
    cacheTime: 0,
    retry: false,
  });
}
